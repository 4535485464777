:root {
  --magenta: #d40f45;
  --light-blue: #21baf6;
  --orange: #ee7e01;
  --purple: #673ab7;
  --light-purple: #9f75a7;
  --dark-purple: #453048;
  --chaos-blue: #21baf6;
  --dark-green: #28472d;
  --chaos-green: #24af3b;
}

html,
body {
  margin: 0;
  padding: 0;
}

html {
  min-height: 100%;
}

.bg {
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  background-position: right;
  position: fixed;
  height: 100vh;
  width: 100vw;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
}

/* material breakpoints */
/* XSmall */
@media screen and (max-width: 599.98px) {
}
@media screen and (min-width: 600px) and (max-width: 959.98px) {
}
/* Medium */
@media screen and (min-width: 960px) and (max-width: 1279.98px) {
}
/* Large */
@media screen and (min-width: 1280px) {
}
/* material breakpoints ends */

.mat-card.shadow {
  box-shadow:
    0px 0px 10px 0px #9b9b9b,
    0 1px 1px 0 #9b9b9b,
    0 1px 3px 0 #9b9b9b !important;
  border-radius: 20px !important;
  box-shadow:
    0px 6px 6px -3px rgb(0 0 0 / 20%),
    0px 10px 14px 1px rgb(0 0 0 / 25%),
    0px 4px 18px 3px rgb(0 0 0 / 20%) !important;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: transparent;
  opacity: 1;
}

.form-field-half {
  width: 100%;
  font-size: small;
}

@media screen and (min-width: 375px) {
  .form-field-half {
    width: 80%;
    font-size: inherit;
    margin-bottom: 1rem;
  }
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  border-radius: 3vh !important;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: var(--magenta) !important;
}
.mat-input-element {
  caret-color: var(--light-purple);
}

.mat-form-field-label {
  color: var(--light-purple);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: var(--light-purple);
}

.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgb(159 117 167 / 0%);
  opacity: 1;
}
.mat-form-field-ripple {
  background-color: rgb(159 117 167 / 0%);
}

/* stepper */
.mat-horizontal-content-container {
  margin-top: 1rem;
}

.mat-step-header .mat-step-icon {
  background-color: var(--light-purple);
  color: #fff;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: var(--dark-purple);
  color: #fff;
}

.mat-input-element {
  color: var(--dark-purple) !important;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: var(--dark-purple);
}

.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: transparent;
}

/* material button global styles begins */

.mat-raised-button {
  font-size: 1em !important;
  font-weight: 600 !important;
  color: var(--dark-purple) !important;
  border-radius: 1000px !important;
}

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  color: var(--dark-purple) !important;
  background-color: var(--orange);
  border-radius: 1000px;
  font-size: 1em;
  font-weight: 600;
  min-width: 8em;
}

.mat-flat-button.mat-secondary,
.mat-raised-button.mat-secondary,
.mat-fab.mat-secondary,
.mat-mini-fab.mat-secondary {
  color: var(--dark-purple) !important;
  background-color: var(--chaos-blue);
  border-radius: 1000px;
  font-size: 1em;
  font-weight: 600;
  min-width: 8em;
}

.mat-flat-button.mat-green,
.mat-raised-button.mat-green,
.mat-fab.mat-green,
.mat-mini-fab.mat-green {
  color: var(--dark-purple) !important;
  background-color: var(--chaos-green);
  border-radius: 1000px;
  font-size: 1em;
  font-weight: 600;
  min-width: 8em;
}

.mat-flat-button.mat-accent,
.mat-raised-button.mat-accent,
.mat-fab.mat-accent {
  color: white !important;
  background-color: var(--purple);
  border-radius: 1000px;
  font-size: 1em;
  font-weight: 600;
  min-width: 8em;
}

.mat-flat-button.mat-warn,
.mat-raised-button.mat-warn,
.mat-fab.mat-warn {
  color: white !important;
  background-color: var(--magenta);
  border-radius: 1000px;
  font-size: 1em;
  font-weight: 600;
  min-width: 8em;
}

/* fam és mini fab gombok ne legyenek szélesek */
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary,
.mat-fab.mat-secondary,
.mat-mini-fab.mat-secondary,
.mat-fab.mat-accent,
.mat-fab.mat-warn {
  min-width: unset;
}

.mat-flat-button.mat-primary.mat-button-disabled {
  background-color: lightgray !important;
  color: rgb(190, 190, 190) !important;
}

/* material button global styles ends */

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--dark-purple) !important;
}

.mat-step-text-label {
  user-select: none;
}

.cdk-overlay-pane {
  max-width: 100% !important;
}
